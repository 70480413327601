import Vue from 'vue';
import { getUpgradePlanWays, genData, saveUpgradePlan, getUpgradePlanDetail, getCurrentYear } from '@/api/basic';
import basicHeader from '@/components/backStageHeader';
import EButton from '@/components/button/index.vue';
import SubTitle from './components/subTitle.vue';
import { Input, RadioGroup, Radio, DatePicker, Dialog, Tooltip } from 'element-ui';
import Upgrade from '../../../../../back-stage/components/upgrade';
// utils
import { getHostUrlPath } from '@/utils/common';
export default {
  name: 'Upgrade-settings',
  components: {
    basicHeader,
    EButton,
    SubTitle,
    [Input.name]: Input,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [DatePicker.name]: DatePicker,
    [Dialog.name]: Dialog,
    [Tooltip.name]: Tooltip,
  },
  data() {
    return {
      fromSchYearName: '',
      newSchYearName: '',
      newSchYearNameTips: '',
      isRightAway: 'scheduledActivation',
      updateWayList: [],
      updateWayTips: '',
      dateTime: '',
      dateTimeTips: '',
      planId: this.$route.query.upgradePlanId || '', // 升年级计划Id
      dialogVisible: false,
      timer: null,
      generateTime: '',
      dataPrepareTips: '',
      toSchYearId: '',
      generateStatus: '', // 用于判断数据是否过期 2为过期。过期则要求用户重新生成数据
      cacheUpgradePlanObjs: null,
      leaveSign: true, // 离开页面是否需要询问的标识符
    };
  },
  created() {
    this.init();
  },
  mounted() {
    window.addEventListener('beforeunload', this.beforeUnloadHandler, false);
  },
  beforeDestroy() {
    clearInterval(this.timer);
    window.removeEventListener('beforeunload', this.beforeUnloadHandler, false);
  },
  beforeRouteLeave(to, from, next) {
    setTimeout(() => {
      if (!this.leaveSign) {
        next();
      } else {
        this.$msgbox
          .confirm('您还有数据没有保存，是否保存退出？', '提示', {
            customClass: 'ql-message-box_notitle',
            confirmButtonText: '保存',
            cancelButtonText: '不保存',
            type: 'warning',
          })
          .then(async () => {
            const rs = await this.save();
            console.log(rs);
            // next();
          })
          .catch(() => {
            next();
          });
      }
    }, 0);
  },
  computed: {
    //是否是新增数据
    isAdd() {
      return !this.$route.query.upgradePlanId;
    },
  },
  methods: {
    getUpgradePlanWaysFunc() {
      return new Promise((resolve) => {
        getUpgradePlanWays({
          status: 0,
        }).then((res) => {
          if (res.status == 0) {
            this.updateWayList = res.result.map((item) => {
              item.sysGrades.forEach((grade) => {
                grade.upgradeWay = item.upgradeWay;
              });

              item.cusGrades.forEach((grade) => {
                grade.upgradeWay = item.upgradeWay;
              });

              return item;
            });
            resolve();
          }
        });
      });
    },
    getSubTips(updateWay) {
      switch (updateWay) {
        case 0:
          return '适用于班级师生无较大变动的情况，升年级后沿用之前的班级、任教关系，点阵笔所属年级也跟随教师任教年级';
        case 1:
          return '适用于班级学生无大变动，需换老师的情况，升年级后需重新导入任教关系，导入任教关系后点阵笔所属年级也跟随教师任教年级';
        case 2:
          return '适用于班级师生变动较大的情况，升年级后需重新导入班级、任教关系，导入任教关系后点阵笔所属年级也跟随教师任教年级';
        default:
          return '适用于毕业年级，毕业后班级、班级内的学生及任教关系将被删除';
      }
    },
    setGradesWay(total, item) {
      if (item.active) {
        Vue.set(item, 'active', false);
      } else {
        total
          .map((i) => [...i.sysGrades, ...i.cusGrades])
          .flat()
          .map((j) => {
            if (j.gradeId === item.gradeId) {
              Vue.set(j, 'active', false);
            }
          });
        Vue.set(item, 'active', true);
      }
    },
    handleUpgradePlanObjs() {
      const selectedsList = this.updateWayList
        .map((item) => [...item.sysGrades, ...item.cusGrades])
        .flat()
        .filter((i) => i.active);

      const upgradePlanObjs = selectedsList.map((item) => {
        return {
          gradeId: item.gradeId,
          gradeName: item.gradeName,
          upgradeWay: item.upgradeWay,
          // upgradeWayName: this.updateWayList.filter((i) => i.upgradeWay === item.upgradeWay)[0].upgradeWayName,
        };
      });

      return upgradePlanObjs;
    },
    async generateData() {
      const { newSchYearNameValid, updateWayValid } = this.validate();
      if (newSchYearNameValid && updateWayValid) {
        const upgradePlanObjs = this.handleUpgradePlanObjs();
        console.log(upgradePlanObjs);
        let confirmRes;
        if (this.planId) {
          const h = this.$createElement;
          confirmRes = await this.$msgbox({
            message: h('div', null, [
              h('h3', { class: 'T3B mg-b-13' }, `确定再次生成吗？`),
              h('p', { class: 'T5-2' }, '再次生成数据，会清除掉上一次准备的数据。'),
            ]),
            type: 'warning',
            customClass: 'ql-message-box_notitle',
            showClose: false,
            showCancelButton: true,
          }).catch((err) => err);
        } else {
          confirmRes = 'confirm';
        }
        if (confirmRes !== 'confirm') return;
        const result = await saveUpgradePlan({
          id: this.planId,
          newSchYearName: this.newSchYearName,
          upgradeStatus: this.isAdd ? -2 : null,
          upgradePlanObjs,
        });
        this.planId = result.result;
        await genData({
          upgradePlanId: this.planId,
        });
        const res = await Upgrade.check('upgradeGenData', this.planId);
        this.generateTime = res.result.upgradePlan.generateTime;
        this.toSchYearId = res.result.upgradePlan.toSchYearId;
        this.cacheUpgradePlanObjs = upgradePlanObjs;
        this.generateStatus = null;
      }
    },
    validate(verifyData = {}) {
      const { needCheckTime, needPlanId } = verifyData;
      let newSchYearNameValid = false;
      let updateWayValid = false;
      let dateTimeValid = false;
      let dataPrepareValid = false;

      // 选中的方式
      const selectedsList = this.updateWayList
        .map((item) => [...item.sysGrades, ...item.cusGrades])
        .flat()
        .filter((i) => i.active);

      // 未选中的方式
      const unSelectedsList = this.updateWayList
        .filter((item) => item.upgradeWay === 3)
        .map((j) => [...j.sysGrades, ...j.cusGrades])
        .flat()
        .filter((v) => !selectedsList.some((k) => k.gradeId === v.gradeId));

      if (this.newSchYearName && this.newSchYearName !== this.fromSchYearName) {
        this.newSchYearNameTips = '';
        newSchYearNameValid = true;
      } else if (this.newSchYearName === this.fromSchYearName) {
        this.newSchYearNameTips = '该学年名称已存在';
        this.$refs.upgradeSettingsRef.parentElement.scrollTop = 0;
      } else {
        this.$refs.upgradeSettingsRef.parentElement.scrollTop = 0;
        this.newSchYearNameTips = '请输入新学年名称';
      }

      if (!selectedsList.length) {
        this.updateWayTips = `未选择升年级方式与对象`;
        this.$refs.upgradeSettingsRef.parentElement.scrollTop = 0;
      } else if (unSelectedsList.length) {
        const str = unSelectedsList.map((i) => i.gradeName).join('、');
        this.updateWayTips = `${str}还未选择升年级方式`;
        this.$refs.upgradeSettingsRef.parentElement.scrollTop = 0;
      } else {
        this.updateWayTips = ``;
        updateWayValid = true;
      }

      if (needCheckTime) {
        if (this.isRightAway === 'immediateActivation') {
          this.dateTimeTips = '';
          dateTimeValid = true;
        } else if (!this.dateTime) {
          this.dateTimeTips = '请选择升年级时间';
        } else if (new Date(this.dateTime) <= new Date().valueOf()) {
          this.dateTimeTips = '升年级时间不可早于当前时间';
        } else {
          this.dateTimeTips = '';
          dateTimeValid = true;
        }
      }

      if (needPlanId) {
        if (this.planId) {
          this.dataPrepareTips = '';
          dataPrepareValid = true;
        } else {
          this.dataPrepareTips = '请点击生成数据按钮';
        }
      }

      return {
        newSchYearNameValid,
        updateWayValid,
        dateTimeValid,
        dataPrepareValid,
      };
    },
    getUpgradePlanDetailFunc() {
      getUpgradePlanDetail({
        upgradePlanId: this.planId,
      }).then((res) => {
        if (res.status == 0) {
          const data = res.result;
          this.fromSchYearName = data.fromSchYearName;
          this.newSchYearName = data.toSchYearName;
          this.generateTime = data.generateTime;
          this.dateTime = data.upgradeTime;
          this.toSchYearId = data.toSchYearId;
          this.generateStatus = data.generateStatus;
          // 处理数据
          this.updateWayList.forEach((item) => {
            item.sysGrades.concat(item.cusGrades).forEach((grade) => {
              const isActive = data.upgradePlanObjs.some(
                (a) => a.gradeId === grade.gradeId && a.upgradeWay === item.upgradeWay
              );
              Vue.set(grade, 'active', isActive);
            });
          });
        }
      });
    },
    getCurrentYearFunc() {
      getCurrentYear().then((res) => {
        if (res.status == 0) {
          this.fromSchYearName = res.result.schoolYearName;
        }
      });
    },
    async save() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve) => {
        const h = this.$createElement;
        // 比较2个对象数组是否相等（不考虑元素顺序和属性顺序）
        function isArrayEqual(arr1, arr2) {
          let map = new Map();

          for (let obj of arr1) {
            let key = JSON.stringify(Object.keys(obj).sort()) + JSON.stringify(Object.values(obj).sort());

            map.set(key, obj);
          }

          for (let obj of arr2) {
            let key = JSON.stringify(Object.keys(obj).sort()) + JSON.stringify(Object.values(obj).sort());

            if (!map.has(key)) {
              return false;
            }
          }

          return true;
        }
        const { newSchYearNameValid, updateWayValid, dateTimeValid, dataPrepareValid } = this.validate({
          needCheckTime: true,
          needPlanId: true,
        });
        const upgradePlanObjs = this.handleUpgradePlanObjs();

        if (
          (this.cacheUpgradePlanObjs && !isArrayEqual(this.cacheUpgradePlanObjs, upgradePlanObjs)) ||
          this.generateStatus === 2
        ) {
          this.$msgbox({
            message: h('div', null, [
              h('h3', { class: 'T3B mg-b-13' }, `升年级方式与对象调整后，未重新在数据准备模块生成数据，是否完成配置？`),
            ]),
            type: 'warning',
            customClass: 'ql-message-box_notitle',
            showClose: false,
            showCancelButton: true,
          })
            .then(async () => {
              this.cacheUpgradePlanObjs = upgradePlanObjs;
              this.generateStatus = null;
              this.save();
            })
            .catch(() => {});
          return;
        }
        if (newSchYearNameValid && updateWayValid && dateTimeValid && dataPrepareValid) {
          const rightnow = new Date().valueOf();
          const targetTime = new Date(this.dateTime).valueOf();
          const data = {
            id: this.planId,
            newSchYearName: this.newSchYearName,
            isRightAway: this.isRightAway === 'immediateActivation' ? true : false,
            upgradeTime: this.dateTime,
            upgradePlanObjs,
          };
          if (targetTime - rightnow > 0) {
            const result = await saveUpgradePlan(data);
            if (result.status == 0) {
              this.$router.push({
                name: 'upgrade-list',
              });
              this.leaveSign = false;
              resolve();
            }
          } else {
            this.$msgbox({
              message: h('div', null, [
                h('h3', { class: 'T3B mg-b-13' }, `系统将在点击完成配置后立即升年级至【${this.newSchYearName}】`),
                h('p', { class: 'T5-2' }, '升年级时间已晚于当前学年的起始时间，这段时间内产生的数据将被归属于历史学年'),
              ]),
              type: 'warning',
              customClass: 'ql-message-box_notitle',
              showClose: false,
              showCancelButton: true,
            }).then(async () => {
              const result = await saveUpgradePlan({
                id: this.planId,
                ...data,
              });
              if (result.status == 0) {
                this.$router.push({
                  name: 'upgrade-list',
                });
                this.leaveSign = false;
                resolve();
              }
            });
          }
        }
      });
    },
    goDataPrepare() {
      window.open(getHostUrlPath(`/data-prepare/class?schoolYearId=${this.toSchYearId}&upgradePlanId=${this.planId}`));
    },
    goBack() {
      this.$router.push({
        name: 'upgrade-list',
      });
    },
    beforeUnloadHandler(e) {
      e.preventDefault();
      e.returnValue = '您在页面编辑了未保存，是否确认离开';
      return '您在页面编辑了未保存，是否确认离开';
    },
    async init() {
      await this.getUpgradePlanWaysFunc();
      if (!this.isAdd) {
        this.getUpgradePlanDetailFunc();
      } else {
        this.getCurrentYearFunc();
      }
    },
  },
};
