<template>
  <div class="upgrade-settings-subTitle">
    <div class="divider"></div>
    <div class="title">{{ title }}</div>
    <div class="tips" v-if="tips">
      <i class="iconfont icon-icon_jinggao"></i>
      <div>{{ tips }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'subtitle',
  props: {
    title: {
      type: String,
      require: true,
    },
    tips: {
      type: String,
    },
  },
};
</script>
<style lang="scss" scoped>
.upgrade-settings-subTitle {
  display: flex;
  align-items: center;
  width: 100%;

  .divider {
    @apply bg-theme;
    width: 4px;
    height: 16px;
    border-radius: 2px 2px 2px 2px;
    margin-right: 8px;
    flex-shrink: 0;
  }

  .title {
    @apply text-F1 T4;
    flex-shrink: 0;
    margin-right: 20px;
  }

  .tips {
    @apply text-error text-14;
    display: flex;
    align-items: center;
    margin-left: auto;

    i {
      margin-right: 10px;
    }
  }
}
</style>
